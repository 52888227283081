<template>
  <div class="container">
    <button
      class="btn btn-close-modal"
      @click="$bvModal.hide('add-person-modal')"
    >
      <span class="material-icons"> close </span>
    </button>
    <!-- row -->
    <div class="row">
      <!-- <p>{{ formpage }}</p> -->
      <h2 class="my-4" style="width: 100%; text-align: center">
        {{ $t('generic-str.register-user') }}
      </h2>
      <div v-if="formpage === true" class="form1" style="width: 100%">
        <!-- Profile img -->
        <div class="col-12 user-picture">
          <div class="profile-img">
            <i class="fas fa-user"></i>
          </div>
          <button class="btn btn-remove mb-3">
            {{ $t('generic-str.remove') }} Avatar
          </button>
        </div>
        <!--/Profile img -->
        <div class="col-12 user-register">
          <!-- <small>ID: 546789</small> -->
          <div class="form-group" style="margin-bottom: 1.75rem">
            <input
              type="text"
              class="form-control"
              :placeholder="`${$t('login-invite.fullname')}`"
              :disabled="isSending"
              required
            />
          </div>
          <fieldset>
            <ul class="list-unstyled genre">
              <li>
                <div class="control-group opt-2">
                  <label class="control control-checkbox">
                    <input type="radio" name="genre" />
                    <div class="control_indicator control-rounded"></div>
                    <span>{{ $t('generic-str.feminine') }}</span>
                  </label>
                </div>
              </li>
              <li>
                <div class="control-group opt-2">
                  <label class="control control-checkbox">
                    <input type="radio" name="genre" />
                    <div class="control_indicator control-rounded"></div>
                    <span>{{ $t('generic-str.masculine') }}</span>
                  </label>
                </div>
              </li>
            </ul>
          </fieldset>
        </div>
        <fieldset class="col-12" style="margin-bottom: 1.75rem">
          <label for="birthdate">{{ $t('generic-str.birthdate') }}: </label>
          <date-picker
            v-model="form.birthDate"
            :config="{
              format: 'DD/MM/YY',
              useCurrent: true,
              showClear: true,
              showClose: true,
            }"
            class="form-control input-date"
            placeholder="--/--/----"
          />
        </fieldset>
        <!-- <fieldset class="col-12 colors" style="margin-bottom: 1.75rem">
          <label for="colors-scheme"
            >{{ $t('generic-str.color-scheme') }}:</label
          >
          <ul class="list-unstyled">
            <li>
              <div class="control-group opt-3">
                <label class="control control-checkbox">
                  <input type="radio" name="colors_scheme" checked />
                  <div class="control_indicator"></div>
                </label>
              </div>
            </li>
            <li>
              <div class="control-group opt-3">
                <label class="control control-checkbox">
                  <input type="radio" name="colors_scheme" />
                  <div class="control_indicator orange"></div>
                </label>
              </div>
            </li>
            <li>
              <div class="control-group opt-3">
                <label class="control control-checkbox">
                  <input type="radio" name="colors_scheme" />
                  <div class="control_indicator yellow"></div>
                </label>
              </div>
            </li>
            <li>
              <div class="control-group opt-3">
                <label class="control control-checkbox">
                  <input type="radio" name="colors_scheme" />
                  <div class="control_indicator blue"></div>
                </label>
              </div>
            </li>
            <li>
              <div class="control-group opt-3">
                <label class="control control-checkbox">
                  <input type="radio" name="colors_scheme" />
                  <div class="control_indicator gray"></div>
                </label>
              </div>
            </li>
            <li>
              <div class="control-group opt-3">
                <label class="control control-checkbox">
                  <input type="radio" name="colors_scheme" />
                  <div
                    class="control_indicator color-selector"
                    :style="{ backgroundColor: form.userColor }"
                    @click="chooseColor = !chooseColor"
                  ></div>
                </label>
              </div>
            </li>
          </ul>
          <color-picker
            v-if="chooseColor"
            :value="form.userColor"
            :disableAlpha="true"
            @input="updateColor"
          />
        </fieldset> -->
      </div>
      <!-- form2 -->
      <form @submit.prevent="invite" v-else-if="formpage === false" class="form2">
        <!-- <h3>Form 2</h3> -->
        <div class="col-12">
          <div class="form-group" v-if="form.role !== 'owner'">
            <label class="control-label">{{ $t('users.lbl-func') }}</label>
            <!-- <div>{{userId}} = {{$store.state.account.id}}</div> -->
            <!-- Função -->
            <select-input
              v-model="form.role"
              :reduce="(roles) => roles.value"
              :options="roles"
              :clearable="false"
              required
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.role"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </select-input>
            <!--/Função -->
            <div
              class="alert-info-primary alert-icon alert-flex"
              style="margin-top: 10px"
              v-if="form.role == 'admin'"
            >
              Permissão total da plataforma, com exceção da contratação de
              produtos ou pacotes de serviço.
            </div>
            <div
              class="alert-info-primary alert-icon alert-flex"
              style="margin-top: 10px"
              v-if="form.role == 'developer'"
            >
              Permissão de configurar webhook, widget (Yuper Chat), analise de
              relatórios da plataforma.
            </div>
            <div
              class="alert-info-primary alert-icon alert-flex"
              style="margin-top: 10px"
              v-if="form.role == 'billing'"
            >
              Permissão a todos os relatórios e área financeira da plataforma.
            </div>
            <div
              class="alert-info-primary alert-icon alert-flex"
              style="margin-top: 10px"
              v-if="form.role == 'user'"
            >
              Ver relatórios, interagir com os canais. (Chat) <!-- (Omni Business) -->
              Atendimento.
            </div>
            <div
              class="alert-info-primary alert-icon alert-flex"
              style="margin-top: 10px"
              v-if="form.role == 'security'"
            >
              Antifraude Gestão.
            </div>
            <div
              class="alert-info-primary alert-icon alert-flex"
              style="margin-top: 10px"
              v-if="form.role == 'sales'"
            >
              Relatórios Gestão.
            </div>
            <!-- Departamento -->
            <label class="control-label" style="margin-top: 1.75rem">{{
              $t('generic-str.department')
            }}</label>
            <select-input
              v-model="form.department"
              :reduce="(departmentsRoles) => departmentsRoles.value"
              @input="(dep) => updateDepartment(form.department)"
              :options="departmentsRoles"
              :clearable="false"
              required
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.role"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </select-input>
            <!--/Departamento -->
          </div>
          <!-- <div class="form-group form_opt" style="margin-top: 1.7rem;">
            <span class="material-symbols-outlined" style="font-weight: 300">
              call
            </span>
            <vue-tel-input
              v-model="mobile_number"
              @validate="numberChanges"
              v-bind="bindProps"
              placeholder="(xx) x xxxx - xxxx"
              style="width: 100%"
            ></vue-tel-input>
            <div class="invalid-feedback" :class="{ show: invalidPhone }">
              {{ $t('login-invite.invalid-phone') }}
            </div>
          </div> -->
          <div class="form-group form_opt">
            <!-- <label for="inputEmail" class="">Email</label> -->
            <!-- <span class="material-symbols-outlined" style="font-weight: 300">
              email
            </span> -->
            <input
              type="email"
              v-model="form.email"
              id="inputEmail"
              class="form-control"
              :placeholder="`${$t('generic-str.example')}@gmail.com`"
              :disabled="isSending"
              required
            />
          </div>
          <!-- <div class="form-group form_opt">
            <span class="material-symbols-outlined" style="font-weight: 300">
              location_on
            </span>
            <input
              type="text"
              class="form-control"
              :placeholder="'São Paulo-SP, CEP: 43873-000'"
              :disabled="isSending"
              required
            />
          </div> -->
          <div class="control-group opt-2 agent_checkbox">
            <label class="control control-checkbox">
              <input v-model="form.is_agent" type="checkbox" />
              <div
                class="control_indicator"
                style="float: left; margin-right: 1rem"
              ></div>
              <span style="float: left"
                >{{ $tc('app.agent', 1) }}<br /><span style="font-size: 0.8rem"
                  >*{{ $t('users.agent') }}</span
                ></span
              >
            </label>
          </div>
        </div>
        <button
          v-if="formpage === false"
          style="width: fit-content;"
          class="btn btn-yup-purple ml-auto"
          type="submit"
        >
          <span>{{$t('generic-str.register')}}</span>
        </button>
      </form>
      <!--/form2 -->
      <div class="w-100"></div>
      <!-- <button
        class="btn btn-block"
        :class="[
          formpage === true ? 'btn-yup-purple' : 'btn-yup-outline-gray',
        ]"
        @click="formpage = !formpage"
        :style="[
          formpage === true
            ? { width: 'fit-content', 'margin-left': 'auto' }
            : { width: 'fit-content', 'margin-right': 'auto' },
        ]"
      >
        <span v-if="formpage === true">{{ $t('generic-str.next') }}</span>
        <span v-else>{{ $t('generic-str.previous') }}</span>
      </button> -->
      <!-- <button
        v-if="formpage === false"
        style="width: fit-content;"
        class="btn btn-yup-purple ml-auto"
        type="submit"
      >
        <span>{{$t('generic-str.register')}}</span>
      </button> -->
    </div>
    <!--/row -->
  </div>
</template>
<script>
import DepartmentService from '@/services/department.service';
import UserService from '@/services/user.service';
import SelectInput from '@/components/SelectInput.vue';
import datePicker from 'vue-bootstrap-datetimepicker';
// import { VueTelInput } from 'vue-tel-input';

export default {
  components: {
    datePicker,
    SelectInput,
    // VueTelInput,
  },
  data() {
    return {
      chooseColor: false,
      fetchedDerpartments: false,
      fetchedUser: false,
      departments: [],
      mobile_number: null,
      invalidPhone: false,
      isSending: false,
      formpage: false,
      form: {
        name: null, // Convite não envia nome
        email: '',
        role: 'user',
        department: null,
        is_agent: false,
        max_calls_at_time: 10,
        // userColor: '#0E1421',
        // birthDate: '',
      },
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  mounted() {
    this.getDepartaments();
  },
  computed: {
    roles() {
      const data = [
        {
          label: this.$t('users.func.lbl-manager'),
          value: 'admin',
        },
        {
          label: this.$t('users.func.lbl-dev'),
          value: 'developer',
        },
        {
          label: this.$t('users.func.lbl-fin'),
          value: 'billing',
        },
        {
          label: this.$t('users.func.lbl-analyst'),
          value: 'user',
        },
      ];

      if (this.$store.state.account.whitelabel_id === null) {
        data.push({
          label: this.$t('users.func.lbl-info-security'),
          value: 'security',
        });
        data.push({
          label: this.$t('users.func.lbl-exec-sale'),
          value: 'sales',
        });
      }

      return data;
    },
    departmentsRoles() {
      /* const data = [
        {
          label: this.$t('home.invite-card.select.commercial'),
          value: 'commercial',
        },
        {
          label: this.$tc('generic-str.lbl-product', 1),
          value: 'product',
        },
        {
          label: this.$t('generic-str.supp'),
          value: 'support',
        },
        {
          label: 'Onboard',
          value: 'onboard',
        },
        {
          label: 'Marketing',
          value: 'marketing',
        },
      ];

      if (this.$store.state.account.whitelabel_id === null) {
        data.push({
          label: this.$t('users.func.lbl-info-security'),
          value: 'security',
        });
        data.push({
          label: this.$t('users.func.lbl-exec-sale'),
          value: 'sales',
        });
      } */

      /* const depArray = this.departments.data;
      console.info('department: ', depArray); */

      const depJsonArray = this.departments; // Get the department object

      let ber = []; // temp array

      Object.entries(depJsonArray).forEach((entry, index) => {
        // const [key, value] = entry // testing purpose
        if (index === 1) {
          // console.log('Aproach test', key, value, 'Index: ', index); // conditional 'if' statement testing purpose
          ber = depJsonArray.data.map((obj) => [obj.id, obj.name]); // obj.description
        }
      });
      const depObject = ber.map(([value, label]) => ({ value, label }));
      console.log('Nice obj', depObject);
      console.log('Nice array: ', ber);
      const result = Object.keys(depJsonArray).map((key) => [Number(key), this.departments[key]]);
      console.log('Converted array: ', result[1]);

      return depObject;
    },
  },
  methods: {
    updateDepartment(e) {
      console.log('Department arrived: ', e);
    },
    invite() {
      this.isSending = true;
      UserService.invite(this.form).then(
        () => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('home.invite-card.invite-success'),
            type: 'success',
          });
          // this.$emit('sent');
          this.isSending = false;
          this.$bvModal.hide('add-person-modal');
          this.$root.$emit('UsersCardReload'); // Call fetch
          // this.$router.push('/users');
          // this.$live.sync();
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
              error.code
            }`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
    numberChanges(rest) {
      if (rest.number.input.length > 0) {
        this.invalidPhone = !rest.isValid;
        if (rest.isValid) {
          this.mobile_number = rest.number.national;
          this.user.mobile_number = rest.number.input;
        }
      }
    },
    getDepartaments() {
      this.fetchedDerpartments = false;
      DepartmentService.getDepartments({}).then(
        (response) => {
          this.fetchedDerpartments = true;
          this.departments = response;
          // this.departments = [1, 2, 3, 4];
        },
        (error) => {
          this.fetchedDerpartments = true;
          this.departments = [];
          this.content = error;
        },
      );
    },
    getUser() {
      this.fetchedUser = false;
      UserService.getUser(this.userId).then(
        (response) => {
          this.fetchedUser = true;
          this.form.name = response.name;
          this.form.email = response.email;
          this.form.department = response.department
            ? response.department.id
            : null;
          this.form.is_agent = response.pivot.is_agent;
          this.form.role = response.pivot.role;
        },
        (error) => {
          this.fetchedUser = true;
          this.content = error;
        },
      );
    },
    updateColor(color) {
      this.form.userColor = color.hex;
    },
  },
};
</script>
<style scoped>
.user-picture,
.profile-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.75rem;
}
.user-picture {
  flex-direction: column;
}
.profile-img {
  align-items: center;
  width: 74px;
  height: 74px;
  background-color: var(--gray-font-color);
  border-radius: 100%;
  margin: auto;
}
.btn-remove {
  width: fit-content;
  margin: auto;
  background-color: #fafafa;
  font-weight: 400;
  color: #bbb;
}
.btn-remove:hover {
  border-color: var(--gray-font-color);
}

.darkmode .btn-remove {
  background-color: var(--background-3);
}
.darkmode .btn-remove:hover {
  color: #fff;
}
.profile-img i {
  color: #fff;
}
.user-register {
  display: flex;
  flex-direction: column;
}
.user-register small {
  margin-bottom: 1.75rem;
  color: var(--gray-font-color);
}
.user-register fieldset.form-group > div:first-child,
.user-register .genre {
  display: flex;
  gap: 1rem;
}

.user-register .genre .control.control-checkbox {
  display: flex;
  gap: 6px;
}

.user-register .genre .control.control-checkbox span {
  margin-top: 2px;
}

.control-group.opt-3 .control_indicator {
  background: var(--clr-yup-purple);
  border: 2px solid var(--clr-yup-purple);
  border-radius: 100%;
  height: 50px;
  width: 50px;
}

.control-group.opt-3 .control_indicator.orange,
.control-group.opt-3 .control:hover input ~ .control_indicator.orange,
.control-group.opt-3 .control input:focus ~ .control_indicator.orange {
  background: #e67e2d;
  border-color: #e67e2d;
}

.control-group.opt-3 .control_indicator.yellow,
.control-group.opt-3 .control:hover input ~ .control_indicator.yellow,
.control-group.opt-3 .control input:focus ~ .control_indicator.yellow {
  background: #e0e62d;
  border-color: #e0e62d;
}

.control-group.opt-3 .control_indicator.blue,
.control-group.opt-3 .control:hover input ~ .control_indicator.blue,
.control-group.opt-3 .control input:focus ~ .control_indicator.blue {
  background: #2d4ce6;
  border-color: #2d4ce6;
}

.control-group.opt-3 .control_indicator.gray,
.control-group.opt-3 .control:hover input ~ .control_indicator.gray,
.control-group.opt-3 .control input:focus ~ .control_indicator.gray {
  background: var(--gray-font-color);
  border-color: var(--gray-font-color);
}

.control-group.opt-3 .control_indicator.color-selector,
.control-group.opt-3 .control:hover input ~ .control_indicator.color-selector,
.control-group.opt-3 .control input:focus ~ .control_indicator.color-selector {
  background: #fff;
  border-color: var(--gray-font-color);
}

.control-group.opt-3 .control_indicator.color-selector[style] {
  border: none;
}

.control-group.opt-3
  .control-checkbox
  .control_indicator.color-selector::after {
  content: '\e5d3';
  display: inline-block;
  color: var(--gray-font-color);
}

.control-group.opt-3 .control:hover input ~ .control_indicator,
.control-group.opt-3 .control input:focus ~ .control_indicator {
  background: var(--clr-yup-purple);
}

.control-group.opt-3 .control-checkbox .control_indicator::after {
  left: 10px;
  top: 10px;
}

.control-group.opt-3 .control-checkbox .control_indicator::after {
  border-width: 0;
  content: '\e5ca';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 28px;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  transform: none;
  color: #fff;
}

.colors ul {
  display: flex;
  gap: 5px;
}

.colors ul li {
  width: fit-content;
}

.btn-close-modal {
  position: absolute;
  right: 0;
}

.btn-close-modal span {
  color: var(--gray-font-color);
}

.form_opt {
  display: flex;
  gap: 0.7rem;
  align-items: center;
  margin-bottom: 1.7rem;
}

.form_opt span {
  color: var(--gray-font-color);
}

.rebranding .vue-tel-input,
.rebranding .vti__dropdown {
  border: unset !important;
}

.rebranding .vue-tel-input::v-deep .vti__dropdown {
  background-color: var(--form-input) !important;
  border-color: var(--form-border) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-right: unset !important;
}

.control-label,
.agent_checkbox span {
  color: var(--gray-font-color);
}

.agent_checkbox > label {
  display: flex;
}

.agent_checkbox .control_indicator {
  width: 26px;
}

.form2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
</style>
