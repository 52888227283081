<template>
  <div
    class="card"
    :class="{
      'card-expand': expanded,
      'card-tabs': tabs,
      border: noShadow,
      'no-shadow': noShadow,
    }"
  >
    <div v-if="!noHeader" class="card-header">
      <div v-if="title" class="card-title header_title">{{ title }}</div>
      <slot name="header" />
      <form
        v-if="searchable"
        @submit.prevent="$emit('search')"
        style="margin-left: auto"
      >
        <div class="form-group">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              v-model="q"
            />
            <div class="input-group-append">
              <button class="btn btn-primary btn-icon" type="submit">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="opts_card" v-if="usersAcc">
        <!-- Ordenar Por -->
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none p-0"
          no-caret
          right
        >
          <template #button-content>
            <span class="material-icons title"> swap_vert </span
            ><span class="sr-only">Search</span>
          </template>
          <div class="card">
            <div class="card-header" style="display: flex; align-items: center">
              {{ $t('generic-str.sort-by') }}
              <b-dropdown-form class="close-btn">
                <b-dropdown-item-button @click="closeDropdown"
                  ><span class="material-icons close-icon"> close </span>
                </b-dropdown-item-button>
              </b-dropdown-form>
            </div>
            <div class="card-body">
              <ul class="list-unstyled">
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $t('generic-str.sort-alphabetical') }}</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $t('generic-str.sort-access') }}</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $tc('app.agent', 1) }}</span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </b-dropdown>
        <!--/Ordenar Por -->
        <!-- Filtros -->
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none p-0"
          no-caret
          right
        >
          <template #button-content>
            <div
              class="title filter_icons"
              style="display: flex; align-items: center"
            >
              <span class="material-icons"> filter_list </span>
              <span class="material-icons angle_down">
                keyboard_arrow_down
              </span>
            </div>
            <span class="sr-only">Search</span>
          </template>
          <div class="card">
            <div class="card-header" style="display: flex; align-items: center">
              <div class="filter-search">
                <div class="input-group">
                  <input placeholder="" type="text" class="form-control" />
                  <div class="input-group-prepend">
                    <button type="button" class="btn icon_btn m-0">
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </div>
              {{ $t('sms.infos.filters.title') }}
              <b-dropdown-form class="close-btn">
                <b-dropdown-item-button @click="closeDropdown"
                  ><span class="material-icons close-icon"> close </span>
                </b-dropdown-item-button>
              </b-dropdown-form>
            </div>
            <div class="card-body">
              <h5>
                <b>{{ $t('app.role') }}</b>
              </h5>
              <ul class="list-unstyled mb-3">
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $t('generic-str.all') }}</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $t('generic-str.filter-adm') }}</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>Sales</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $tc('generic-str.user', 1) }}</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>CEO</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $tc('app.agent', 1) }}</span>
                    </label>
                  </div>
                </li>
              </ul>
              <!-- Departamentos -->
              <h5>
                <b>{{ $tc('app.department', 1) }}</b>
              </h5>
              <ul class="list-unstyled">
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $t('generic-str.all') }}</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $t('generic-str.filter-sale') }}</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $t('generic-str.filter-executive') }}</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>Marketing</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input type="checkbox" />
                      <div class="control_indicator"></div>
                      <span>{{ $t('generic-str.supp') }}</span>
                    </label>
                  </div>
                </li>
              </ul>
              <!--/Departamentos -->
            </div>
          </div>
        </b-dropdown>
        <!--/Filtros -->
        <!-- <span class="material-icons-outlined title"> file_upload </span> -->
        <a id="show-btn" href="javascript:;" @click="showModal"
          ><span class="material-symbols-outlined add_person"> add </span></a
        >
        <b-modal
          id="add-person-modal"
          ref="add-person"
          size="md"
          scrollable
          hide-header
          hide-footer
        >
          <div class="container-fluid">
            <AddPerson />
          </div>
        </b-modal>
        <a
          style="display: flex; align-items: center"
          href="javascript:void(0)"
          v-if="actions"
          @click="reload"
          ><span class="material-symbols-outlined title"> refresh </span></a
        >
      </div>
      <div class="opts_card" v-if="usersDep">
        <span class="material-icons-outlined title"> domain </span>
        <span class="material-icons-outlined add_person"> add </span>
        <a
          style="display: flex; align-items: center"
          href="javascript:void(0)"
          v-if="actions"
          @click="reload"
          ><span class="material-symbols-outlined title"> refresh </span></a
        >
      </div>
      <ul v-if="actions" class="actions top-right">
        <li>
          <a href="javascript:void(0)" @click="reload"
            ><span class="material-symbols-outlined title"> refresh </span></a
          >
        </li>
        <li>
          <a href="javascript:void(0)" @click="expand">
            <!-- <i v-if="expanded" class="dripicons-contract-2"></i> -->
            <!-- <i v-else class="icon dripicons-expand-2"></i> -->
            <span v-if="expanded" class="material-symbols-outlined title"
              >close_fullscreen</span
            >
            <span v-else class="material-symbols-outlined title"
              >open_in_full</span
            >
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body" :class="{ 'block-el': block }">
      <div class="loading min-h-300" v-if="block && loading">
        <LoadingAnim />
      </div>
      <slot v-else name="body" />
    </div>
    <slot />
    <div v-if="footer" class="card-footer bg-light">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import AddPerson from '@/components/rebranding/modalContent/AddPerson.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'Card',
  components: {
    AddPerson,
    LoadingAnim,
  },
  props: {
    usersAcc: Boolean,
    usersDep: Boolean,
    noHeader: Boolean,
    noShadow: Boolean,
    tabs: Boolean,
    title: String,
    block: Boolean,
    searchable: Boolean,
    /* search: {
      type: String,
      default: 'q',
    }, */
    actions: Boolean,
    footer: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    reload() {
      this.$emit('reload');
    },
    expand() {
      this.expanded = !this.expanded;
    },
    closeDropdown() {
      this._okToHide = true;
      this.$refs.dropdown.hide();
    },
    showModal() {
      this.$refs['add-person'].show();
    },
    hideModal() {
      this.$refs['add-person'].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['add-person'].toggle('#toggle-btn');
    },
  },
};
</script>

<style lang="scss" scoped>
.block-el {
  min-height: 100px;
}
.card-title {
  margin-bottom: 0;
}
.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}

.filter-search .btn {
  padding: 0;
}
.icon_btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: transparent;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  span {
    color: var(--gray-font-color);
  }
}

::v-deep.loading-icon-lottie {
  position: absolute;
}
</style>
